import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {AntaresModule} from 'ng-antares/core';
import {AntaresInjectOutside} from 'ng-antares/core/util/antares-inject-outside';
import {AntsPushSettingsComponent} from './ants-push-settings.component';
import {PushMessageComponent} from './admin-push-message/push-message.component';
import {PushMessageFormComponent} from './admin-push-message/push-message-form.component';
import {PushMessageApiService} from './admin-push-message/push-message-api.service';
import {PushMessageConfigService} from './admin-push-message/push-message-config.service';
import {AntsPushRoutingModule} from 'app-src/app/ants-push/ants-push-routing.module';
import {AntsPushUserMessagesComponent} from 'app-src/app/ants-push/ants-push-user-messages.component';

AntaresInjectOutside.getInstance().addComponent(AntsPushSettingsComponent);
AntaresInjectOutside.getInstance().addComponent(AntsPushUserMessagesComponent);

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FormsModule,
        AntaresModule,
        AntsPushRoutingModule,
    ],
    declarations: [
        AntsPushSettingsComponent,
        AntsPushUserMessagesComponent,
        PushMessageComponent,
        PushMessageFormComponent,
    ],
    entryComponents: [
        AntsPushSettingsComponent,
        AntsPushUserMessagesComponent,
    ],
    providers: [
        PushMessageApiService,
        PushMessageConfigService,
    ],
})
export class AntsPushModule {
}
