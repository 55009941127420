define(function(require) {
    'use strict';

    var moment = require('moment');

    var PrintTime = function() {
        this.timeFormat = 'llll';
        this.dateFormat = 'll';
    };

    PrintTime.prototype.printTime = function(time, format) {
        if (arguments[0] === '_self_') {
            return this;
        }

        if (typeof format === 'undefined') {
            format = this.timeFormat;
        }

        return this.formatTime(time, format);
    };

    PrintTime.prototype.printDate = function(time, format) {
        if (typeof format === 'undefined') {
            format = this.dateFormat;
        }

        return this.formatTime(time, format);
    };

    PrintTime.prototype.formatTime = function(time, format) {
        if (_.isString(time)) {
            return moment(time).format(format);
        } else if (_.isObject(time) && !_.isEmpty(time.date)) {
            time = time.date.replace('.000000', '');
            return moment(time).format(format);
        } else {
            return '';
        }
    };

    PrintTime.prototype.moment = function(time) {
        if (_.isString(time)) {
            return moment(time);
        } else if (_.isObject(time) && !_.isEmpty(time.date)) {
            return moment(time.date);
        } else {
            return moment();
        }
    };

    return PrintTime;
});
