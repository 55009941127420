define(function (require) {
    'use strict';

    var Geppetto = require('geppetto');
    var BaseForm = require('wlants-site/views/auth/base');
    var PasswordStrength = require('wlants-common/plugins/password-strength');
    require('intlTelInput');

    return BaseForm.extend({
        el: $('#user_register_form_wrap'),
        template: false,
        ui: {
            form: 'form',
            submitBtn: '.submit-btn',
            passwordInput: 'form input[name=password]',
        },
        events: {
            'click @ui.submitBtn': function (e) {
                e.preventDefault();
                this.onFormSubmit();
            },
            'keyup @ui.passwordInput': function (e) {
                var p = $(e.target).val();
                $(e.target).removeClass('strength-0');
                $(e.target).removeClass('strength-1');
                $(e.target).removeClass('strength-2');
                $(e.target).addClass('strength-' + this.passwordStrength.getStrength(p));
            },
        },
        initialize: function () {
            Geppetto.bindContext({
                view: this,
                context: Geppetto.Context,
                parentContext: this.options.parentContext,
            });
            this.helpers = this.helpersLocator.getHelpers();
            this.passwordStrength = new PasswordStrength();
            this.telInput = $('#phone-number').intlTelInput();
            this.telInput.intlTelInput('loadUtils', '/static/intl-tel-input/lib/libphonenumber/build/utils.js');
            if (window._appEnv === 'development') {
                this.$el.find('input[name=email]').parent().parent().find('.control-label').on('click', () => {
                    this.$el.find('input[name=firstName]').val('Dejan');
                    this.$el.find('input[name=lastName]').val('Stjepanovic');
                    this.$el.find('input[name=email]').val('stj.dejan@gmail.com');
                    this.$el.find('form input[name=password]').val('12345asdASD');
                    this.$el.find('form input[name=passwordConfirm]').val('12345asdASD');
                });
            }
        },
        onFormSubmit: function () {
            if (!this.getSubmitEnabled()) {
                return;
            }
            this.setSubmitEnabled(false);
            this.removeFormMessages();
            this.removeFlashMessage();
            var url = this.context.getObject('app').getConfig().site.urls.register;
            var data = this.ui.form.serializeObject();
            data['g-recaptcha-response'] = this.ui.form.find('#g-recaptcha-response').val();
            var countryData = this.telInput.intlTelInput('getSelectedCountryData');
            data.countryName = countryData.name;
            data.countryIso = countryData.iso2;
            data.dialCode = countryData.dialCode;
            this.showWaitDialog();
            var self = this;
            $.ajax({
                url: url,
                data: data,
                type: 'post',
                dataType: 'json',
                complete: function (response) {
                    self.onSubmitDone(response);
                },
            });
        },
        onSubmitDone: function (response) {
            if (response.status === 200) {
                var dialog = this.dialogs.infoDialog(response.responseJSON.message);
                this.listenToOnce(dialog, 'hidden', function () {
                    window.location = '/';
                });
            } else {
                if (response.responseJSON.validation_messages) {
                    this.setFormMessages(response.responseJSON.validation_messages);
                } else {
                    var msg;
                    if (response.responseJSON.detail) {
                        msg = response.responseJSON.detail;
                    } else {
                        msg = this.helpers.translate('Error');
                    }
                    this.setFlashMessage(msg);
                }
            }
            this.setSubmitEnabled(true);
            this.reloadRecaptcha();
            this.hideWaitDialog();
        },
    });
});
