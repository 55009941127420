import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {AntaresModule} from 'ng-antares/core';
import {AntaresInjectOutside} from 'ng-antares/core/util/antares-inject-outside';
import {WlantsPaymentInitComponent} from 'app-src/app/wlants-payment/wlants-payment-init.component';
import {WlantsPaymentPricingComponent} from 'app-src/app/wlants-payment/wlants-payment-pricing.component';

AntaresInjectOutside.getInstance().addComponent(WlantsPaymentInitComponent);
AntaresInjectOutside.getInstance().addComponent(WlantsPaymentPricingComponent);

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FormsModule,
        AntaresModule,
    ],
    declarations: [
        WlantsPaymentInitComponent,
        WlantsPaymentPricingComponent,
    ],
    entryComponents: [
        WlantsPaymentInitComponent,
        WlantsPaymentPricingComponent,
    ],
    providers: [],
})
export class WlantsPaymentModule {
}
