// import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/mouse';
import 'select2';
import * as jqueryUiTouchPunch from 'jquery.ui.touch-punch';
import {window} from 'ng-antares/core/util';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from 'app-src/app/app.module';
import {environment} from 'app-src/environments/environment';
import initBbApp from 'bb-app/BbApp';

(window as any).jQuery = $;

jqueryUiTouchPunch($);

// $('body').on('click', '.nav-link', function () {
//     $(this).closest('.nav-tabs').find('.nav-link').removeClass('active');
//     $(this).addClass('active');
// });
//
// if (window._enabledModules.indexOf('WlantsDemo') > -1) {
//     $('.tool-block, .tools-nav li').show();
// } else {
//     $.ajax({
//         url: '/member-api/ping',
//         complete: (r) => {
//             if (typeof r.responseJSON.data.availableTools !== 'undefined') {
//                 for (let tool of r.responseJSON.data.availableTools) {
//                     $('*[data-id="' + tool + '"]').show();
//                 }
//             }
//         },
//     });
// }

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));

initBbApp();
