define(function(require) {
    'use strict';

    var Marionette = require('marionette');

    var HelpersLocator = require('wlants-common/services/helpers-locator');
    var logger = require('wlants-common/services/logger');
    var config = require('wlants-common/config');

    var NlbrHelper = require('wlants-common/helpers/nlbr');
    var PrintTimeHelper = require('wlants-common/helpers/print-time');
    var RouteHelper = require('wlants-common/helpers/route');
    var TranslateHelper = require('wlants-common/helpers/translate');

    var WaitingIndicator = require('wlants-common/services/waiting-indicator');
    var EditorHandler = require('wlants-common/services/editor-handler');

    return Marionette.Object.extend({
        initialize: function(opts) {
            this.context = opts.context;
            this.listenToOnce(this.context.getObject('app'), 'bootstrap', this.bootstrap);
            this.listenToOnce(this.context.getObject('app'), 'run', this.run);
        },
        bootstrap: function() {
            this.context.getObject('app').addConfig(config);
            this.initContext();
            this.initHelpers();
        },
        run: function() {
            var waitingIndicator = new WaitingIndicator();
            waitingIndicator.init();
        },
        initContext: function() {
            this.wireObject(logger, 'logger');
            this.wireObject(HelpersLocator, null, 'helpersLocator');
            this.context.wireSingleton('editorHandler', EditorHandler);
        },
        wireObject: function(object, valueName, signletonName) {
            if (typeof valueName !== 'undefined') {
                this.context.wireValue(valueName, object);
            }
            if (typeof signletonName !== 'undefined') {
                this.context.wireSingleton(signletonName, object);
                if (signletonName.indexOf('Collection') > -1) {
                    this.context.configure(signletonName, undefined, {
                        context: this.context
                    });
                } else {
                    this.context.configure(signletonName, {
                        context: this.context
                    });
                }
            }
        },
        initHelpers: function() {
            var helpersLocator = this.context.getObject('helpersLocator');
            helpersLocator.registerHelper('nlbr', NlbrHelper);
            helpersLocator.registerHelper('printTime', PrintTimeHelper);
            helpersLocator.registerHelper('route', RouteHelper);
            helpersLocator.registerHelper('translate', TranslateHelper);
        }
    });
});
