define(function (require) {
    'use strict';

    var Marionette = require('marionette');

    var config = require('wlants-demo/config');

    var DemoPopupView = require('wlants-demo/views/demo/popup');
    var DemoTimerView = require('wlants-demo/views/demo/timer');

    return Marionette.Object.extend({
        initialize: function (opts) {
            this.context = opts.context;
            this.listenToOnce(this.context.getObject('app'), 'bootstrap', this.bootstrap);
            this.listenToOnce(this.context.getObject('app'), 'run', this.run);
        },
        bootstrap: function () {
            this.context.getObject('app').addConfig(config);
            this.initContext();
        },
        run: function () {
            this.init();
        },
        initContext: function () {

        },
        wireObject: function (object, valueName, signletonName) {
            if (typeof valueName !== 'undefined') {
                this.context.wireValue(valueName, object);
            }
            if (typeof signletonName !== 'undefined') {
                this.context.wireSingleton(signletonName, object);
                if (signletonName.indexOf('Collection') > -1) {
                    this.context.configure(signletonName, undefined, {
                        context: this.context
                    });
                } else {
                    this.context.configure(signletonName, {
                        context: this.context
                    });
                }
            }
        },
        init: function () {
            if (window.location.pathname.match(/^\/admin/)) {
                return;
            }
            if (window._demoEnabled && !window.location.pathname.match(/\/contact/)) {
                $('body').append('<div class="modal-backdrop fade in tmp-backdrop" style="background-color: transparent;"></div>');
                $.ajax({
                    url: '/member-api/ping',
                    complete: (r) => {
                        $('.tmp-backdrop').remove();
                        if (r.responseJSON.data.identity) {
                            var demoTimerView = new DemoTimerView({
                                parentContext: this.context,
                                remainingDemoTime: r.responseJSON.data.remainingDemoTime
                            });
                            demoTimerView.render();
                            $('.demo-timer-holder').html('').append(demoTimerView.$el);
                        } else {
                            var demoPopupView = new DemoPopupView({
                                parentContext: this.context,
                                alreadyUsedDemo: r.responseJSON.data.alreadyUsedDemo
                            });
                            demoPopupView.init();
                        }
                    }
                });
            }
        }
    });
});
