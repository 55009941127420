declare let window: any;
declare let define: any;
// declare let $: any;

window.$ = require('jquery');
window.jQuery = require('jquery');
require('jqueryUi');
require('bootstrap3');
require('jquerySerializeSbject');
require('jquery.cookie');
require('zozoAccordion');

// require('backbone');
// require('jqueryTimePickerAddon');
//
// require('jquerySerializeSbject');
// require('select2');
// require('lightbox2');
require('underscore');
require('underscore.string');
require('underscore.deepclone');
// require('bootstrap');
// require('backbone.radio');
// require('marionette');
// require('geppetto');
// require('slick-carousel');
// // require('imagesloaded');
// require('magnific-popup');
// // require('elevatezoom');
// // require('bootstrapSelect');
// // require('jquerylazyload');
// require('wnumb');

let Marionette = require('marionette');
let Geppetto = require('geppetto');

// let alias: any = {
//     'wnumb': './node_modules/wnumb/wNumb',
// };

// let marionette = require('marionette');
// console.log(marionette);
//
// // let test = require('src/bb-app/modules/wlants-dialog/test');
// let test = require('wlants-dialog/test');
// console.log(test);

// import * as $ from 'jquery';
// import './vendor/jquery-serialize-object/jquery.serialize-object';

let initBbApp: any = function () {
    let app: any = new Marionette.Application({
        routers: [],
        config: {},
        initialize: function () {
            Geppetto.bindContext({
                view: this,
                context: Geppetto.Context.extend({
                    wiring: {
                        values: {
                            app: this,
                        },
                    },
                }),
            });
        },
        initModule: function (Module) {
            return new Module({
                context: app.context,
            });
        },
        addRouter: function (Router) {
            let router = new Router({
                context: this.context,
            });
            this.routers.push(router);
        },
        getConfig: function () {
            return this.config;
        },
        addConfig: function (config) {
            this.config = $.extend(true, this.config, config);
        },
    });

    app.on('start', function () {
        this.initModule(require('modules/wlants-dialog/module'));
        this.initModule(require('modules/wlants-common/module'));
        // this.initModule(require('modules/wlants-admin/module'));
        this.initModule(require('modules/wlants-site/module'));
        this.initModule(require('modules/wlants-demo/module'));

        this.trigger('bootstrap');
        this.trigger('run');
    });

    window['app'] = app;

    window['bbAppInitialized'] = true;

    $(window).scroll(function () {
        if ($(window).scrollTop() >= 120) {
            $('#header-simple').addClass('fixed-header');
        } else {
            $('#header-simple').removeClass('fixed-header');
        }
    });
    $('.with-tooltip .fa-question').hover(function (e) {
        e.preventDefault();
        $('.with-tooltip').toggleClass('active');
    });
    $('.with-tooltip .fa-question').on('tap', function () {
        $('.with-tooltip').toggleClass('active');
    });
};

export default initBbApp;

export const startBbApp = (config: {
    locale: string;
    translations: any;
}) => {
    window._defaultLocale = config.locale;
    window._locale = config.locale;
    window['_tr_labels_' + config.locale] = config.translations;
    let interval: any = setInterval(() => {
        if (window['bbAppInitialized']) {
            clearInterval(interval);
            window['app'].start();
        }
    }, 200);
};
