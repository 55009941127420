define(function(require) {
    'use strict';

    var Marionette = require('marionette');

    return Marionette.Object.extend({
        initialize: function() {
            this.context = this.options.context;
        },
        initEditor: function(element) {
            var elementId = $(element).attr('id');
            if (!elementId) {
                elementId = 'editor_' + parseInt(Math.random() * 100000000);
                $(element).attr('id', elementId);
            }
            var config = {};
            // config.toolbar = [
            //     {
            //         name: 'basicstyles',
            //         items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat']
            //     },
            //     {name: 'links', items: ['Link', 'Unlink']},
            //     {
            //         name: 'paragraph',
            //         items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CodeSnippet']
            //     },
            //     {name: 'tools', items: ['Source', 'Maximize']},
            //     '/',
            //     {
            //         name: 'clipboard',
            //         items: ['Cut', 'Copy', 'Paste', 'PasteText', '-', 'Undo', 'Redo']
            //     },
            //     {name: 'insert', items: ['Table', 'HorizontalRule', 'SpecialChar']}
            // ];
            var editor = CKEDITOR.replace(elementId, config);
            // CKFinder.setupCKEditor(editor, '/static/ckfinder');
        },
        getData: function(element) {
            var elementId = $(element).attr('id');
            var data = '';
            if (typeof CKEDITOR.instances[elementId] !== 'undefined') {
                data = CKEDITOR.instances[elementId].getData();
            }
            return data;
        }
    });
});
