define(function (require) {
    'use strict';

    var BaseForm = require('wlants-site/views/auth/base');
    var Geppetto = require('geppetto');

    return BaseForm.extend({
        el: '#user_login_form_wrap',
        template: false,
        ui: {
            'form': 'form',
            'email': 'input[name=email]',
            'password': 'input[name=password]',
            'submitBtn': '.submit-btn'
        },
        events: {
            'click @ui.submitBtn': function (e) {
                e.preventDefault();
                this.onFormSubmit();
            }
        },
        initialize: function () {
            Geppetto.bindContext({
                view: this,
                context: Geppetto.Context,
                parentContext: this.options.parentContext
            });
            this.helpers = this.helpersLocator.getHelpers();
            if (window._appEnv === 'development') {
                this.$el.find('input[name=email]').parent().parent().find('.control-label').on('click', () => {
                    this.ui.email.val('mlz.d3y4n@gmail.com');
                    this.ui.password.val('123asdASD!');
                });
            }
        },
        onFormSubmit: function () {
            if (!this.getSubmitEnabled()) {
                return;
            }
            this.setSubmitEnabled(false);
            this.removeFormMessages();
            this.removeFlashMessage();
            var data = this.$el.find(this.ui.form).serializeObject();
            if (!data.email || !data.password) {
                this.setFormMessages({
                    password: [
                        this.helpers.translate('Please fill all required fileds')
                    ]
                });
                this.setSubmitEnabled(true);
            } else {
                this.doLogin(data);
            }
        },
        doLogin: function (data) {
            var self = this;
            var url = this.context.getObject('app').getConfig().site.urls.login;
            $.ajax({
                url: url,
                data: data,
                type: 'post',
                dataType: 'json',
                complete: function (response) {
                    console.log(response);
                    self.onLoginDone(response);
                }
            });
        },
        onLoginDone: function (response) {
            if (response.status === 200) {
                window.location = '/member/home';
            } else {
                var msg;
                if (response.responseJSON.detail) {
                    msg = response.responseJSON.detail;
                } else {
                    msg = this.helpers.translate('Error');
                }
                this.setFormMessages(response.responseJSON.validation_messages);
                this.setFlashMessage(msg);
            }
            this.setSubmitEnabled(true);
        }
    });
});
