define(function(require) {
    'use strict';

    var Marionette = require('marionette');
    var Geppetto = require('geppetto');

    var tpl = require('raw-loader!wlants-demo/templates/demo/popup.jhtml');

    return Marionette.ItemView.extend({
        template: _.template(tpl),
        ui: {
            demoInit: '.btn-demo-init'
        },
        events: {
            'click @ui.demoInit': function(e) {
                e.preventDefault();
                this.demoInit();
            }
        },
        initialize: function() {
            Geppetto.bindContext({
                view: this,
                context: Geppetto.Context,
                parentContext: this.options.parentContext
            });
        },
        init: function() {
            this.render();
            $('body').append(this.$el);
            $('.demo-init-modal').modal({
                show: true,
                backdrop: 'static'
            });
        },
        demoInit: function() {
            $.ajax({
                url: '/api/demo-init',
                type: 'post',
                complete: function(response) {
                    if (response.status === 200) {
                        window.location.reload();
                    } else if (response.status === 406) {
                        alert('Already used demo.');
                    } else {
                        alert('Error');
                    }
                }
            });
        },
        serializeData: function(){
            return {
                translate: this.context.getObject('helpersLocator').getHelpers().translate,
                alreadyUsedDemo: this.options.alreadyUsedDemo
            }
        }
    });
});
