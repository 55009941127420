define(function(require) {
    'use strict';

    var HelpersLocator = function(opts) {
        this.context = opts.context;

        this.helpersConfig = {};
        this.helpers = {};
    };
    HelpersLocator.prototype.registerHelper = function(name, Helper) {
        this.helpersConfig[name] = Helper;
        var obj, fn;
        obj = new Helper({
            context: this.context
        });
        fn = obj[name];
        this._addHelper(name, obj, fn);
    };
    HelpersLocator.prototype.getHelpers = function() {
        return this.helpers;
    };
    HelpersLocator.prototype._addHelper = function(name, obj, fn) {
        this.helpers[name] = function() {
            return fn.apply(obj, arguments);
        };
    };

    return HelpersLocator;
});
