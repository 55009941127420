import {AfterViewChecked, Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';

interface InitPaymentData {
    formData: {
        [key: string]: string;
    };
    url: string;
    fake?: boolean;
}

@Component({
    selector: 'app-wlants-payment-init',
    template: `
        <div style="display: none;" *ngIf="formData.length">
            <form action="{{formUrl}}" method="post" class="form-horizontal" #form>
                <ng-container *ngFor="let row of formData; index as i;">
                    <div class="form-group">
                        <label class="col-xs-4 control-label">
                            {{row.name}}
                        </label>
                        <div class="col-xs-8">
                            <input name="{{row.name}}" value="{{row.value}}" class="form-control" type="text">
                        </div>
                    </div>
                </ng-container>
                <button type="submit" #submitBtn>submit</button>
            </form>
        </div>
    `,
})
export class WlantsPaymentInitComponent implements OnInit, AfterViewChecked {
    @ViewChild('form') form: ElementRef;
    @ViewChild('submitBtn') submitBtn: ElementRef;
    protected isConfirmed: boolean = false;
    protected isSubmitted: boolean = false;
    public formUrl: string;
    public formData: Array<{
        name: string;
        value: string;
    }> = [];

    constructor(protected injector: Injector) {
    }

    public ngOnInit(): void {
        this.getPayButton().show();
        this.bindClick();
    }

    protected bindClick(): void {
        let self = this;
        this.getPayButton().on('click', function () {
            let pricingPlanId: number = Number($(this).attr('data-pricing'));
            let url: string = String($(this).attr('data-url'));
            self.initPayment(pricingPlanId, url);
        });
    }

    public ngAfterViewChecked(): void {
        if (this.isSubmitted) {
            return;
        }
        if (!this.form || !this.form.nativeElement) {
            return;
        }
        this.isSubmitted = true;
        $(this.form.nativeElement).trigger('submit');
    }

    protected initPayment(pricingPlanId: number, url: string): void {
        if (this.idDisabledPayButton()) {
            return;
        }
        this.isConfirmed = $('input[name=confirmRecurring]').prop('checked');
        // console.log('confirmed. init', pricingPlanId, url, this.isConfirmed);
        if (!this.isConfirmed) {
            return;
        }
        this.disablePayButton();
        $.ajax({
            url: url,
            type: 'post',
            data: {
                pricingPlanId: pricingPlanId,
            },
            complete: (r) => {
                if (r.status === 401) {
                    alert('Please login');
                } else if (r.status === 200) {
                    this.onInitPaymentSuccess(r.responseJSON);
                } else {
                    alert('Error');
                }
            },
        });
    }

    protected onInitPaymentSuccess(data: InitPaymentData): void {
        console.log('success', data);
        if (typeof data.fake !== 'undefined' && data.fake) {
            window.location.href = data.formData.return_url;
            return;
        }
        this.formUrl = data.url;
        let formData: Array<{
            name: string;
            value: string;
        }> = [];
        for (let i in data.formData) {
            if (data.formData.hasOwnProperty(i)) {
                formData.push({
                    name: i,
                    value: data.formData[i],
                });
            }
        }
        this.formData = formData;
    }

    protected idDisabledPayButton(): boolean {
        return this.getPayButton().hasClass('disabled');
    }

    protected disablePayButton(): void {
        this.getPayButton().addClass('disabled');
    }

    protected getPayButton(): any {
        return $('.pricing-plan-confirm');
    }
}
