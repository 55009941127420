define(function(require) {
    'use strict';

    var WaitingIndicator = function() {
        this.startedJobs = 0;
    };

    WaitingIndicator.prototype.init = function() {
        this.reset();
        this.initAjaxHandler();
        this.loop();
    };

    WaitingIndicator.prototype.initAjaxHandler = function() {
        var self = this;
        $(document).ajaxSend(function() {
            self.startedJobs++;
            self.start();
        });
        $(document).ajaxComplete(function() {
            self.startedJobs--;
            if (self.startedJobs === 0) {
                self.stop();
            }
        });
    };

    WaitingIndicator.prototype.start = function() {
        if (this.started !== true) {
            this.started = true;
            this.stopped = false;
            this.showIndicator();
        }
    };

    WaitingIndicator.prototype.stop = function() {
        this.stopped = true;
    };

    WaitingIndicator.prototype.reset = function() {
        this.started = false;
        this.stopped = false;
    };

    WaitingIndicator.prototype.showIndicator = function() {
        $('a').each(function(){
            $(this).data('cursor', $(this).css('cursor'));
        });
        $('body, button, .btn, a').css({
            cursor: 'wait'
        });
    };

    WaitingIndicator.prototype.hideIndicator = function() {
        $('body, button, .btn').css({
            cursor: 'auto'
        });
        $('button, .btn').css({
            cursor: 'pointer'
        });
        $('a').each(function(){
            if ($(this).data('cursor')) {
                $(this).css('cursor', $(this).data('cursor'));
            }
        });
    };

    WaitingIndicator.prototype.loop = function() {
        if (this.started === true && this.stopped === true) {
            this.reset();
            this.hideIndicator();
        }
        var self = this;
        setTimeout(function() {
            self.loop();
        }, 500);
    };

    return WaitingIndicator;
});
