import {Injectable} from '@angular/core';
import {AntaresConfigService} from 'ng-antares/core/service';

@Injectable({
    providedIn: 'root',
})
export class PushMessageConfigService extends AntaresConfigService {
    protected load(): void {
        super.load();
        this.config['apiBaseUrl'] = this.config['baseUrl']; // remove lang slug
        this.config['endpoints'] = {
            pushMessage: '/admin-api/push-message',
        };
    }
}
