import {Component, Injector} from '@angular/core';
import {AdminCrudListComponent} from 'ng-antares/admin/crud';
import {ModelPushMessage} from './models';
import {PushMessageApiService} from './push-message-api.service';

@Component({
    template: `
        <div class="animated fadeIn">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header p-0 pl-1 pr-1">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="p-1">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <div class="p-1">
                                            <antares-simple-search [(data)]="filters" (onClear)="clearSearch()"
                                                                   (onSubmit)="filter()"></antares-simple-search>
                                        </div>
                                        <div class="p-1">
                                            <select class="form-control form-control-sm" (change)="filter()"
                                                    [(ngModel)]="filters.status">
                                                <option value="">-- {{'Status' | translate}} --</option>
                                                <option value="SUCCESS">{{'SUCCESS' | translate}}</option>
                                                <option value="FAILED">{{'FAILED' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-1">
                                    <div class="d-flex justify-content-end align-items-center">
                                        <div class="p-1">
                                            <button type="button" class="btn btn-sm btn-primary pull-right m-0"
                                                    [routerLink]="['/admin/push-message/add']">
                                                <i class="fa fa-file-o"></i>
                                                {{'Add' | translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-sm table-hover table-striped" *ngIf="dataReady">
                                    <thead>
                                    <tr>
                                        <th>{{'Title' | translate}}</th>
                                        <th>{{'Message' | translate}}</th>
                                        <th>{{'Url' | translate}}</th>
                                        <th>{{'Push status' | translate}}</th>
                                        <th>{{'Created at' | translate}}</th>
                                    </tr>
                                    </thead>
                                    <tbody #listElement>
                                    <ng-container *ngFor="let row of rows; index as i;">
                                        <tr [attr.data-id]="row.id">
                                            <td>
                                                {{row.title}}
                                            </td>
                                            <td>
                                                {{row.message}}
                                            </td>
                                            <td>
                                                <div *ngIf="row.url">
                                                    <a href="{{row.url}}" target="_blank">
                                                        {{row.url}}
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <span class="badge badge-default badge-status badge-status-{{row.status}}" *ngIf="row.sendPush">
                                                    {{row.status | translate}}
                                                </span>
                                                <span *ngIf="!row.sendPush">
                                                    -- --
                                                </span>
                                            </td>
                                            <td>
                                                {{row.ctime | antaresDate}}
                                            </td>
                                        </tr>
                                    </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            <antares-pagination [data]="rowsData" (onPageChange)="gotoPage($event)"
                                                *ngIf="rowsData"></antares-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
})
export class PushMessageComponent extends AdminCrudListComponent {
    public rows: Array<ModelPushMessage> = [];
    public filters: any = {
        search: '',
        status: '',
        type: '',
    };
    protected apiService: PushMessageApiService;
    protected entityName: string = 'pushMessage';

    constructor(protected injector: Injector) {
        super(injector);
        this.apiService = this.injector.get(PushMessageApiService);
    }

    protected onNavParams(params: any): void {
        this.filters.fkLessonPartId = params.parentId;
        super.onNavParams(params);
    }

    protected onDataReady(): void {
        super.onDataReady();
        this.setBreadcrumbs();
    }

    protected setBreadcrumbs(): void {
        let items: Array<Object> = [];
        items.push({
            url: '/admin/push-message/list',
            label: this.translate.instant('Announcements'),
        });
        this.breadcrumbsService.setItems(items);
    }
}
