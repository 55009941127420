define([], function() {
    'use strict';

    return {
        site: {
            urls: {
                login: '/member-api/login',
                logout: '/member-api/logout',
                register: '/member-api/register',
                requestResetPassword: '/member-api/request-reset-password',
                resetPassword: '/member-api/reset-password',
                changePassword: '/member-api/change-password',
                contact: '/member-api/contact',
                socketAuth: '/member-api/socket-auth'
            }
        }
    };
});
