define(function(require) {
    'use strict';

    var Nlbr = function() {
    };

    Nlbr.prototype.nlbr = function(string, to) {
        if (arguments[0] === '_self_') {
            return this;
        }

        if (!string) {
            return string;
        }
        if (to === '2nl') {
            return string.replace(/<br\s*[\/]?>/gi, "\r\n");
        } else if (to === '2br') {
            return string.replace(/[\r\n?]/g, '<br />');
        } else {
            return string;
        }
    };

    return Nlbr;
});
