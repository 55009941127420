import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MissingTranslationService} from 'ng-antares/core/service';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ChartsModule} from 'ng2-charts/ng2-charts';
import {AppRoutingModule} from './app.routing';
import {AppComponent} from './app.component';
import {AntaresModule} from 'ng-antares/core';
import {HotkeyModule} from 'angular2-hotkeys';
import {Observable, of} from 'rxjs';
import {window} from 'ng-antares/core/util';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AntaresAdminModule} from 'ng-antares/admin';
import {WlantsPaymentModule} from 'app-src/app/wlants-payment/wlants-payment.module';
import {AntsPushModule} from './ants-push/ants-push.module';

class EmptyTranslateLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        return of({});
    }
}

export function createTranslateLoader(http: HttpClient) {
    if (window._appEnv !== 'testing') {
        let suffix: string = '.json';
        if (window._cacheStr) {
            suffix += '?' + window._cacheStr;
        }
        return new TranslateHttpLoader(http, window._localesPublicDir + '/', suffix);
    } else {
        // disable translatins for testing env
        return new EmptyTranslateLoader();
    }
}

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ChartsModule,
        FormsModule,
        HttpClientModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'decreasing',
            closeButton: true,
        }),
        HotkeyModule.forRoot(),
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        TranslateModule.forRoot({
            missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MissingTranslationService},
            isolate: false,
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            },
        }),
        AntaresModule,
        AntaresAdminModule,
        AppRoutingModule,
        WlantsPaymentModule,
        AntsPushModule,
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
    ],
    bootstrap: [AppComponent],
    entryComponents: [],
})
export class AppModule {
}
