import {Component, Injector, OnInit} from '@angular/core';
import * as $ from 'jquery';

@Component({
    selector: 'app-wlants-payment-pricing',
    template: ``,
})
export class WlantsPaymentPricingComponent implements OnInit {
    public baseUrl: string;

    constructor(protected injector: Injector) {
    }

    public ngOnInit(): void {
        this.updateVisiblePricing();

        $('input[name=pricing-time]').on('click', () => {
            this.updateVisiblePricing();
        });

        $('.pricing-plans .submit-btn').on('click', function () {
            let el = $(this).closest('.pricing-plan').find('.price-block:visible');
            let id: string = el.attr('data-id');
            let url: string = $(this).closest('.pricing-plans').attr('data-url');
            url = url.replace('_ID_', id);
            window.location.href = url;
        });
    }

    protected updateVisiblePricing(): void {
        this.setVisiblePricing(this.getFilterDuration());
    }

    protected getFilterDuration(): number {
        let el = $('input[name=pricing-time]:checked');
        if (el.length > 0) {
            return Number(el.closest('.price-filter').attr('data-duration'));
        } else {
            return null;
        }
    }

    protected setVisiblePricing(duration: number): void {
        $('.price-block').hide();
        let el = $('.price-block[data-duration=' + duration + ']');
        el.show();
        let savePercent: string = el.attr('data-save_percent');
        if (savePercent) {
            $('.save-percent-main').show().text('-' + savePercent + '%');
        } else {
            $('.save-percent-main').hide();
        }
    }
}
