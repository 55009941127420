import {Component, Injector, OnInit} from '@angular/core';
import {ModelPushUserMessage} from 'app-src/app/ants-push/models';

@Component({
    selector: 'app-ants-push-user-messages',
    template: `
        <div class="ants-push-user-messages" *ngIf="ready">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="messages-wrap">
                            <ng-container *ngFor="let message of messages; index as i;">
                                <div class="message-single" [class.unread]="!message._embedded.seen">
                                    <div class="message-title">
                                        <a href="{{message.url}}" target="_blank" *ngIf="message.url">
                                            {{message.title}}
                                        </a>
                                        <ng-container *ngIf="!message.url">
                                            {{message.title}}
                                        </ng-container>
                                    </div>
                                    <div class="message-message">
                                        <a href="{{message.url}}" target="_blank" *ngIf="message.url">
                                            {{message.message}}
                                        </a>
                                        <ng-container *ngIf="!message.url">
                                            {{message.message}}
                                        </ng-container>
                                    </div>
                                    <div class="message-time">
                                        {{message.ctime | antaresDate:'date'}}
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
})
export class AntsPushUserMessagesComponent implements OnInit {
    public ready: boolean = false;
    public messages: Array<ModelPushUserMessage> = [];
    protected apiUrl: string = '/member-api/push-message';

    constructor(protected injector: Injector) {
    }

    public ngOnInit(): void {
        if ((window as any)._enabledModules.indexOf('AntsPush') < 0) {
            return;
        }
        this.loadData().catch(console.log);
    }

    public markSeen(): void {
        let ids: Array<number> = [];
        for (let m of this.messages) {
            if (!m._embedded.seen) {
                ids.push(m.id);
            }
        }
        if (ids.length > 0) {
            $.ajax({
                url: this.apiUrl,
                type: 'PATCH',
                data: {
                    ids: ids,
                },
                complete: (r) => {
                    if (r.status === 200) {
                        // console.log(r.responseJSON);
                    }
                },
            });
        }
    }

    protected updateNavItem(): void {
        let count: number = 0;
        for (let m of this.messages) {
            if (!m._embedded.seen) {
                count++;
            }
        }
        if (count > 0) {
            $('#ants-push-user-messages-nav-item').append(`<span class="counter">${count}</span>`);
        }
    }

    protected async loadData(): Promise<void> {
        $.ajax({
            url: this.apiUrl,
            type: 'GET',
            complete: (r) => {
                if (r.status === 200) {
                    this.messages = r.responseJSON._embedded.pushMessage;
                    this.ready = true;
                    this.updateNavItem();
                    this.markSeen();
                }
            },
        });
    }
}
