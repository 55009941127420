define(function(require) {
    'use strict';

    var Marionette = require('marionette');
    var Geppetto = require('geppetto');

    return Marionette.ItemView.extend({
        template: false,
        ui: {
            monthSelect: '.month-select',
            yearSelect: '.year-select',
            submitBtn: '.submit-btn'
        },
        events: {
            'click @ui.submitBtn': function(e) {
                e.preventDefault();
                var month = $(this.ui.monthSelect).val();
                var year = $(this.ui.yearSelect).val();
                if (!month || !year) {
                    return;
                }
                var url = $(this.ui.submitBtn).data('url_tpl');
                url = url.replace('_MONTH_', month);
                url = url.replace('_YEAR_', year);
                window.location = url;
            }
        },
        wiring: [
            'logger',
            'helpersLocator',
            'dialogs'
        ],
        initialize: function() {
            Geppetto.bindContext({
                view: this,
                context: Geppetto.Context,
                parentContext: this.options.parentContext
            });
            this.helpers = this.helpersLocator.getHelpers();
        },
        init: function() {

        }
    });
});
