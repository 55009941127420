define(function(require) {
    'use strict';

    var Dialog = require('wlants-dialog/dialog');

    var Dialogs = function(opts) {
        this.context = opts.context;
    };

    Dialogs.prototype.waitDialog = function(args) {
        if (typeof args === 'string') {
            args = {msg: args};
        } else if (typeof args === 'undefined') {
            args = {};
        }
        var dialog = new Dialog({
            parentContext: this.context
        });
        var el = $('<div></div>');
        $(el).html(args.msg);
        //dialog.setHeadTitle('<i class="fa fa-hourglass-half"></i> Please wait');
        dialog.setBodyContent(el);
        dialog.setButtons([]);
        dialog.render();
        dialog.listenTo(dialog, 'hidden', function() {
            dialog.clear();
        });
        dialog.show();
        return dialog;
    };

    Dialogs.prototype.confirmDialog = function(args) {
        if (typeof args === 'string') {
            args = {msg: args};
        } else if (typeof args === 'undefined') {
            args = {};
        }
        var dialog = new Dialog({
            parentContext: this.context
        });
        var el = $('<div></div>');
        $(el).html(args.msg);
        dialog.setBodyContent(el);
        dialog.setButtons(['yes', 'no']);
        dialog.render();
        dialog.listenTo(dialog, 'actionYes', function(e) {
            if (typeof args.onYes !== 'undefined') {
                args.onYes(dialog, e);
            }
            if (typeof args.hideOnClick === 'undefined' || args.hideOnClick) {
                dialog.hide();
            }
        });
        dialog.listenTo(dialog, 'actionNo', function(e) {
            if (typeof args.onNo !== 'undefined') {
                args.onNo(dialog, e);
            }
            if (typeof args.hideOnClick === 'undefined' || args.hideOnClick) {
                dialog.hide();
            }
        });
        dialog.listenTo(dialog, 'hidden', function() {
            dialog.clear();
        });
        dialog.show();
        return dialog;
    };
    Dialogs.prototype.alertDialog = function(args) {
        if (typeof args === 'string') {
            args = {msg: args};
        } else if (typeof args === 'undefined') {
            args = {};
        }
        var dialog = new Dialog({
            parentContext: this.context
        });
        var el = $('<div></div>');
        $(el).html(args.msg);
        dialog.setBodyContent(el);
        if (typeof args.showBtns === 'undefined' || args.showBtns) {
            var btns = [];
            if (typeof args.closeText === 'undefined') {
                btns.push('close');
            } else {
                btns.push({'btnClass': 'close', 'btnText': args.closeText});
            }
            dialog.setButtons(btns);
        }
        dialog.render();
        dialog.listenTo(dialog, 'actionClose', function(e) {
            if (typeof args.onClose !== 'undefined') {
                args.onClose(dialog);
            }
            if (typeof args.hideOnClick === 'undefined' || args.hideOnClick) {
                dialog.hide();
            }
        });
        dialog.listenTo(dialog, 'hidden', function() {
            dialog.clear();
        });
        dialog.show();
        return dialog;
    };
    Dialogs.prototype.infoDialog = function(args) {
        if (typeof args === 'string') {
            args = {msg: args};
        } else if (typeof args === 'undefined') {
            args = {};
        }
        var dialog = new Dialog({
            parentContext: this.context
        });
        var el = $('<div></div>');
        $(el).html(args.msg);
        dialog.setBodyContent(el);
        if (typeof args.showBtns === 'undefined' || args.showBtns) {
            var btns = [];
            if (typeof args.closeText === 'undefined') {
                btns.push('close');
            } else {
                btns.push({'btnClass': 'close', 'btnText': args.closeText});
            }
        }
        dialog.setButtons(btns);
        dialog.render();
        dialog.listenTo(dialog, 'actionClose', function(e) {
            if (typeof args.onClose !== 'undefined') {
                args.onClose(dialog);
            }
            if (typeof args.hideOnClick === 'undefined' || args.hideOnClick) {
                dialog.hide();
            }
        });
        dialog.listenTo(dialog, 'hidden', function() {
            dialog.clear();
        });
        dialog.show();
        return dialog;
    };
    Dialogs.prototype.setFormMessages = function(form, messages) {
        for (var name in messages) {
            var msgs = messages[name];
            for (var i in msgs) {
                var el = $(form).find('*[name=' + name + ']');
                var wrap = $(el).closest('.form-group');
                $(wrap).addClass('has-error');
                if (!$(wrap).find('.error-block').length) {
                    $('<div class="error-block"><ul></ul></div>').insertAfter(el);
                }
                $(wrap).find('.error-block ul').append('<li>' + msgs[i] + '</li>');
            }
        }
    };
    Dialogs.prototype.clearFormMessages = function(form) {
        $(form).find('.error-block').each(function() {
            $(this).fadeOut(300, function() {
                $(this).remove();
            });
        });
    };

    return Dialogs;
});
