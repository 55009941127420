define(function(require) {
    'use strict';

    var Marionette = require('marionette');

    var Dialogs = require('wlants-dialog/dialogs');

    return Marionette.Object.extend({
        initialize: function(opts) {
            this.context = opts.context;
            this.listenToOnce(this.context.getObject('app'), 'bootstrap', this.bootstrap);
            this.listenToOnce(this.context.getObject('app'), 'run', this.run);
        },
        bootstrap: function() {
            this.context.wireSingleton('dialogs', Dialogs);
            this.context.configure('dialogs', {
                context: this.context
            });
        },
        run: function() {
        }
    });
});
