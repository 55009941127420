define(function (require) {
    'use strict';

    var Helper = function (opts) {

    };
    Helper.prototype.translate = function (label) {
        if (arguments[0] === '_self_') {
            return this;
        }

        var labels = window['_tr_labels_' + window._locale];
        var defaultLabels = window['_tr_labels_' + window._defaultLocale];

        try {
            if (typeof labels[label] !== 'undefined' && labels[label] !== '') {
                return labels[label];
            } else if (typeof defaultLabels !== 'undefined' && typeof defaultLabels[label] !== 'undefined') {
                return defaultLabels[label];
            } else {
                console.log('missing label: ' + label);
                if (window._logMissingLabels) {
                    $.get('/?missing-label=' + label);
                }
                return label;
            }
        } catch (err) {
            console.debug('translate error: ' + err.message + '; for label: ' + label);
            return label;
        }
    };

    return Helper;
});
