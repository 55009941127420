define(function(require) {
    'use strict';

    var Geppetto = require('geppetto');
    var Marionette = require('marionette');
    var tpl = require('raw-loader!wlants-site/templates/labels-test.jhtml');

    return Marionette.ItemView.extend({
        template: _.template(tpl),
        tagName: 'div',
        wiring: [
            'logger',
            'helpersLocator',
            'dialogs'
        ],
        initialize: function() {
            Geppetto.bindContext({
                view: this,
                context: Geppetto.Context,
                parentContext: this.options.parentContext
            });
            this.helpers = this.helpersLocator.getHelpers();
        },
        init: function() {
            this.render();
            this.$el.append(this.helpers.translate('Test text 3') + '<br/>');
            this.$el.append(window._translate('Test text 4') + '<br/>');
        },
        serializeData: function() {
            return {
                enums: this.context.getObject('app').getConfig().enums,
                helpers: this.helpersLocator.getHelpers()
            };
        }
    });
});
