/*global define */

define([], function () {
    'use strict';

    var PasswordStrength = function () {

    };

    PasswordStrength.prototype.getStrength = function (p) {
        var w = 0;
        var m = 1;
        var s = 2;
        if (p.length <= 6) {
            return w;
        }
        var n = /[0-9]+/.test(p);
        var l = /[a-z]+/.test(p);
        var u = /[A-Z]+/.test(p);
        var o = /[^0-9a-z]+/i.test(p);
        var i = 0;
        if (n) {
            i++;
        }
        if (l) {
            i++;
        }
        if (u) {
            i++;
        }
        if (o) {
            i++;
        }
        if (i < 2) {
            return w;
        }
        if (i >= 2 && i <= 3) {
            return m;
        }
        if (i > 3) {
            return s;
        }
    };

    return PasswordStrength;
});
