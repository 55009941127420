define(function(require) {
    'use strict';

    var Marionette = require('marionette');
    var tpl = require('raw-loader!wlants-common/templates/flash-messages.jhtml');

    return Marionette.ItemView.extend({
        tagName: 'div',
        className: 'alert-messages',
        template: _.template(tpl),
        ui: {},
        events: {},
        initialize: function() {

        },
        setMessage: function(message) {
            this.message = message;
            return this;
        },
        show: function() {
            //$(this.$el).insertAfter($('header'));
            $('.main-content').prepend(this.$el);
            //this.$el.css({'top': '108px'});
        },
        serializeData: function() {
            return {
                data: {
                    message: this.message
                }
            };
        }
    });
});
