define([], function() {
    'use strict';

    return {
        enums: {
            user: {
                status: {
                    active: 'ENABLED',
                    blocked: 'DISABLED'
                }
            }
        }
    };
});
