define(function (require) {
    'use strict';

    var Geppetto = require('geppetto');
    var BaseForm = require('wlants-site/views/auth/base');

    return BaseForm.extend({
        el: $('#user_request_reset_password_form_wrap'),
        template: false,
        ui: {
            form: 'form',
            submitBtn: '.submit-btn',
        },
        events: {
            'click @ui.submitBtn': function (e) {
                e.preventDefault();
                this.onFormSubmit();
            },
        },
        initialize: function () {
            Geppetto.bindContext({
                view: this,
                context: Geppetto.Context,
                parentContext: this.options.parentContext,
            });
            this.helpers = this.helpersLocator.getHelpers();
        },
        onFormSubmit: function () {
            if (!this.getSubmitEnabled()) {
                return;
            }
            this.setSubmitEnabled(false);
            this.removeFormMessages();
            this.removeFlashMessage();
            var url = this.context.getObject('app').getConfig().site.urls.requestResetPassword;
            var data = this.ui.form.serializeObject();
            var self = this;
            this.showWaitDialog();
            $.ajax({
                url: url,
                data: data,
                type: 'post',
                dataType: 'json',
                complete: function (response) {
                    self.onSubmitDone(response);
                },
            });
        },
        onSubmitDone: function (response) {
            if (response.status === 200) {
                var dialog = this.dialogs.infoDialog(this.helpers.translate('SUCCESS_CHECK_INBOX_MESSAGE'));
                this.listenToOnce(dialog, 'hidden', function () {
                    window.location = '/';
                });
            } else {
                if (response.responseJSON.validation_messages) {
                    this.setFormMessages(response.responseJSON.validation_messages);
                } else {
                    var msg;
                    if (response.responseJSON.detail) {
                        msg = response.responseJSON.detail;
                    } else {
                        msg = this.helpers.translate('Error');
                    }
                    this.setFlashMessage(msg);
                }
            }
            this.setSubmitEnabled(true);
            this.hideWaitDialog();
        },
    });
});
