import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PushMessageComponent} from './admin-push-message/push-message.component';
import {PushMessageFormComponent} from './admin-push-message/push-message-form.component';

const routes: Routes = [
    {
        path: 'push-message',
        children: [
            {
                path: 'list',
                component: PushMessageComponent,
            },
            {
                path: 'add',
                component: PushMessageFormComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AntsPushRoutingModule {
}
