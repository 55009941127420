define(function(require) {
    'use strict';

    var Geppetto = require('geppetto');
    var BaseForm = require('wlants-site/views/auth/base');

    return BaseForm.extend({
        el: $('#user_reset_password_form_wrap'),
        template: false,
        ui: {
            form: 'form',
            submitBtn: '.submit-btn'
        },
        events: {
            'click @ui.submitBtn': function(e) {
                e.preventDefault();
                this.onFormSubmit();
            }
        },
        initialize: function() {
            Geppetto.bindContext({
                view: this,
                context: Geppetto.Context,
                parentContext: this.options.parentContext
            });
            this.helpers = this.helpersLocator.getHelpers();
        },
        onFormSubmit: function() {
            if (!this.getSubmitEnabled()) {
                return;
            }
            this.setSubmitEnabled(false);
            this.removeFormMessages();
            this.removeFlashMessage();
            var url = this.context.getObject('app').getConfig().site.urls.resetPassword;
            var data = this.ui.form.serializeObject();
            var self = this;
            $.ajax({
                url: url,
                data: data,
                type: 'post',
                dataType: 'json',
                complete: function(response) {
                    self.onSubmitDone(response);
                }
            });
        },
        onSubmitDone: function(response) {
            var msg;
            if (response.status === 200) {
                window.location = '/';
            } else {
                if (response.responseJSON.validation_messages) {
                    if (!response.responseJSON.validation_messages.code) {
                        this.setFormMessages(response.responseJSON.validation_messages);
                    } else {
                        msg = '';
                        for (var i in response.responseJSON.validation_messages.code) {
                            msg += response.responseJSON.validation_messages.code[i] + ' ';
                        }
                        this.setFlashMessage(msg)
                    }
                } else {
                    msg = '';
                    if (response.responseJSON.detail) {
                        msg = response.responseJSON.detail;
                    } else {
                        msg = this.helpers.translate('Error');
                    }
                    this.setFlashMessage(msg);
                }
            }
            this.setSubmitEnabled(true);
        }
    });
});
