import {Injectable, Injector} from '@angular/core';
import {AntaresApiService} from 'ng-antares/core/service';
import {PushMessageConfigService} from './push-message-config.service';

@Injectable({
    providedIn: 'root',
})
export class PushMessageApiService extends AntaresApiService {
    protected entityName: string = 'pushMessage';

    constructor(protected injector: Injector) {
        super(injector);
        this.config = this.injector.get(PushMessageConfigService);
    }
}
