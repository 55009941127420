define(function(require) {
    'use strict';

    var Marionette = require('marionette');
    var introJs = require('intro.js').introJs;

    return Marionette.Object.extend({
        initialize: function(options) {
            this.context = options.context;
            this.listenToOnce(this.context.getObject('app'), 'run', this.init);
        },
        init: function() {
            if (!window._tools_intro_enabled || !window.introOptions || getCookie('_authorized') !== '1') {
                return;
            }
            var self = this;
            $('.show-intro-again').on('click', function(e) {
                e.preventDefault();
                self.reinitIntro();
            });
            this.initIntro();
        },
        initIntro: function() {
            var cookieName = this.getCookieName();
            if ($.cookie(cookieName) === '1') {
                return;
            }
            $.cookie(cookieName, '1', {expires: 365, path: '/'});
            this.showIntro();
        },
        reinitIntro: function() {
            var cookieName = this.getCookieName();
            $.cookie(cookieName, '0', {expires: 365, path: '/'});
            this.initIntro();
        },
        getCookieName: function() {
            return 'intro_' + window.introOptions.page;
        },
        showIntro: function() {
            var intro = introJs();
            intro.setOptions(window.introOptions);
            intro.start();
        }
    });
});
