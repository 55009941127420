define(function (require) {
    'use strict';

    var Marionette = require('marionette');
    var Geppetto = require('geppetto');
    var _string = require('underscore.string');

    var tpl = require('raw-loader!wlants-demo/templates/demo/timer.jhtml');

    return Marionette.ItemView.extend({
        template: _.template(tpl),
        initialize: function () {
            Geppetto.bindContext({
                view: this,
                context: Geppetto.Context,
                parentContext: this.options.parentContext
            });
            this.remaining = this.options.remainingDemoTime;
            this.listenTo(this, 'render', function () {
                this.loopTimer();
            });
        },
        loopTimer: function () {
            var self = this;

            if (this.remaining < 0) {
                $.ajax({
                    url: '/member-api/ping',
                    complete: (r) => {
                        window.location.href = '/member/home';
                    }
                });
                return;
            }

            this.updateTimer();

            this.remaining--;

            setTimeout(function () {
                self.loopTimer();
            }, 1000);
        },
        updateTimer: function () {
            var m = Math.floor(this.remaining / 60);
            var s = this.remaining % 60;
            m = _string.lpad(m, 2, '0');
            s = _string.lpad(s, 2, '0');
            this.$el.find('.m').text(m);
            this.$el.find('.s').text(s);
        }
    });
});
