define(function(require) {
    'use strict';

    var Marionette = require('marionette');
    var FlashMessages = require('wlants-common/views/flash-messages');

    return Marionette.ItemView.extend({
        submitEnabled: true,
        setSubmitEnabled: function(flag) {
            this.submitEnabled = flag;
        },
        getSubmitEnabled: function() {
            return this.submitEnabled;
        },
        setFlashMessage: function(message) {
            this.fm = new FlashMessages();
            this.fm.setMessage(message).render().show();
        },
        removeFlashMessage: function() {
            if (!this.fm) {
                return;
            }
            this.fm.destroy();
            this.fm = null;
        },
        wiring: [
            'logger',
            'helpersLocator',
            'dialogs'
        ],
        setFormMessages: function(messages) {
            var group;
            for (var i in messages) {
                if (i === 'captcha') {
                    group = this.$el.find('.g-recaptcha').closest('.form-group');
                } else {
                    group = this.$el.find('*[name=' + i + ']').closest('.form-group');
                }
                group.addClass('has-error');
                var err = $('<span class="help-block help-block-error"></span>');
                $(err).html(_.first(_.values(messages[i])));
                $(group).append($(err));
            }
        },
        removeFormMessages: function() {
            this.$el.find('.form-group').removeClass('has-error');
            this.$el.find('.help-block-error').each(function() {
                $(this).slideUp(10, function() {
                    $(this).remove();
                });
            });
        },
        reloadRecaptcha: function() {
            if (typeof grecaptcha !== 'undefined') {
                try {
                    grecaptcha.reset();
                } catch (err) {
                }
            }
        },
        showWaitDialog: function() {
            var self = this;
            this.hideDialogRequested = false;
            setTimeout(function() {
                if (!self.hideDialogRequested) {
                    self.waitDialog = self.dialogs.waitDialog(
                        self.helpersLocator.getHelpers().translate('Please wait')
                    );
                }
            }, 800);
        },
        hideWaitDialog: function() {
            this.hideDialogRequested = true;
            if (this.waitDialog) {
                this.waitDialog.hide();
                this.waitDialog = null;
            }
        }
    });
});
