import {Component, Injector} from '@angular/core';
import {AdminCrudFormComponent} from 'ng-antares/admin/crud';
import {ModelPushMessage} from './models';
import {PushMessageApiService} from './push-message-api.service';
import * as _ from 'lodash';

@Component({
    selector: 'admin-ants-push-message-form',
    template: `
        <div class="animated fadeIn">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header p-0 pl-1 pr-1">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="p-1">
                                    <div class="d-flex justify-content-start align-items-center">
                                    </div>
                                </div>
                                <div class="p-1">
                                    <div class="d-flex justify-content-end align-items-center">
                                        <div class="p-1">
                                            <button type="button" class="btn btn-sm btn-outline-primary pull-right m-0"
                                                    [routerLink]="['/admin/push-message/list']">
                                                <i class="fa fa-arrow-circle-o-left"></i>
                                                {{'Back' | translate}}
                                            </button>
                                        </div>
                                        <div class="p-1">
                                            <button type="button" class="btn btn-sm btn-success pull-right m-0"
                                                    (click)="save($event)" [disabled]="!dataReady">
                                                <i class="fa fa-save"></i>
                                                {{'Save' | translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-5">
                                    <form class="antares-form" method="POST" id="antares-from" #form>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <label>{{'Title' | translate}}</label>
                                                    <input type="text" class="form-control" name="title"
                                                           [(ngModel)]="rowData.title">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <label>{{'Message' | translate}} *</label>
                                                    <input type="text" class="form-control" name="message"
                                                           [(ngModel)]="rowData.message">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <label>{{'Url' | translate}}</label>
                                                    <input type="text" class="form-control" name="url"
                                                           [(ngModel)]="rowData.url">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <label>{{'Send push notification?' | translate}} *</label>
                                                    <select class="form-control" name="sendPush"
                                                            [(ngModel)]="rowData.sendPush">
                                                        <option value="1">{{'Yes' | translate}}</option>
                                                        <option value="0">{{'No' | translate}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
})
export class PushMessageFormComponent extends AdminCrudFormComponent {
    protected apiService: PushMessageApiService;
    protected entityName: string = 'pushMessage';
    protected saveMethod: string = 'jQuery';
    public rowData: ModelPushMessage;

    constructor(protected injector: Injector) {
        super(injector);
        this.apiService = this.injector.get(PushMessageApiService);
        this.beforeInit();
    }

    protected onDataReady(): void {
        super.onDataReady();
        this.setBreadcrumbs();
        if (this.isNewEntity) {
            this.rowData.status = '';
            this.rowData.sendPush = '1';
        }
    }

    protected setBreadcrumbs(): void {
        let items: Array<Object> = [];
        items.push({
            url: '/admin/push-message/list',
            label: this.translate.instant('Announcements'),
        });
        if (this.isNewEntity) {
            items.push({
                label: this.translate.instant('Add announcement'),
            });
        } else {
            items.push({
                label: this.translate.instant('Edit announcement'),
            });
        }
        this.breadcrumbsService.setItems(items);
    }

    protected redirectToEdit(): void {
        this.router.navigate(['/admin/' + _.kebabCase(this.entityName) + '/list']);
    }
}
