import {Component, Injector, OnInit} from '@angular/core';

declare const OneSignal: any;

@Component({
    selector: 'app-ants-push-user-settings',
    template: `
        <div class="form-group form-check" *ngIf="ready">
            <input type="checkbox" class="form-check-input" id="andsPushUserSettings"
                   name="enabled" [(ngModel)]="enabled" (change)="updateEnabled()">
            <label class="form-check-label" for="andsPushUserSettings">
                &nbsp;
                Receive push notifications
            </label>
        </div>
    `,
})
export class AntsPushSettingsComponent implements OnInit {
    public ready: boolean = false;
    public enabled: boolean = false;
    protected apiUrl: string = '/member-api/push-user-settings';

    constructor(protected injector: Injector) {
    }

    public ngOnInit(): void {
        if ((window as any)._enabledModules.indexOf('AntsPush') < 0) {
            return;
        }
        this.loadData().catch(console.log);
    }

    public updateEnabled(): void {
        $.ajax({
            url: this.apiUrl,
            type: 'post',
            data: {
                enabled: this.enabled ? '1' : '0',
            },
            complete: (r) => {
                console.log(r);
                if (r.status === 200) {
                    if (this.enabled) {

                    } else {

                    }
                    // console.log(OneSignal);
                }
            },
        });
    }

    protected async loadData(): Promise<void> {
        $.ajax({
            url: this.apiUrl,
            type: 'get',
            complete: (r) => {
                if (r.status === 200) {
                    this.enabled = r.responseJSON.enabled;
                    this.ready = (this.enabled === true || this.enabled === false);
                }
            },
        });
    }
}
