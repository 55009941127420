import {Component, ComponentFactoryResolver, Injector, OnInit, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AntaresInjectOutside, window} from 'ng-antares/core/util';
import {FlexTranslationService} from 'ng-antares/core/service';
import {startBbApp} from 'bb-app/BbApp';
import {SocketClient} from 'app-src/app/socket-client';
import has = Reflect.has;

@Component({
    selector: 'app-root',
    template: `
        <div class="main-loader" style="display: none;">Loading...</div>
        <router-outlet></router-outlet>
    `,
})
export class AppComponent implements OnInit {
    constructor(protected router: Router,
                protected viewContainerRef: ViewContainerRef,
                protected componentFactoryResolver: ComponentFactoryResolver,
                protected translate: TranslateService,
                protected flexTranslation: FlexTranslationService,
                protected injector: Injector) {
    }

    public ngOnInit(): void {
        this.initTranslation(this.translate).then(() => {
            this.initFlexTranslation().then(() => {
                this.initNavigation();
                this.injectOutside();
                startBbApp({
                    locale: window._locale,
                    translations: this.translate.store.translations[window._locale],
                });
                if (typeof window._subscribeChannels !== 'undefined') {
                    new SocketClient(this.injector).init(window._subscribeChannels);
                }
            });
        });
    }

    protected initNavigation(): void {
        let adminAppPage: boolean = window.location.pathname === '/admin/app'
            || window.location.pathname === '/admin/app/';
        let hash: string = window.location.hash.replace('#', '');
        let hashForAdmin: boolean = hash.indexOf('/admin') === 0;

        if (!adminAppPage && hashForAdmin) {
            hash = '';
        }
        if (adminAppPage && hash === '') {
            hash = '/admin/user/list';
        }

        if (window.location.hash.replace('#', '') !== hash) {
            window.location.hash = hash;
        }

        if (hash !== '') {
            this.router.navigate([hash]);
        }
    }

    protected injectOutside(): void {
        AntaresInjectOutside.getInstance().trigger(this.componentFactoryResolver, this.viewContainerRef);
    }

    protected initTranslation(translate: TranslateService): Promise<any> {
        return translate.use(window._locale).toPromise();
    }

    protected initFlexTranslation(): Promise<any> {
        return this.flexTranslation.load();
    }
}
