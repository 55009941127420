import * as io from 'socket.io-client';
import {window} from 'ng-antares/core/util';
import * as moment from 'moment';
import * as $ from 'jquery';
import {Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AntaresModalService} from 'ng-antares/core/modals/antares-modal.service';
import * as pako from 'pako';

export class SocketClient {
    protected translate: TranslateService;
    protected modalService: AntaresModalService;
    protected channels: Array<string>;

    public constructor(protected injector: Injector) {
        this.translate = this.injector.get(TranslateService);
        this.modalService = this.injector.get(AntaresModalService);
    }

    public init(channels: Array<string>): void {
        this.channels = channels;
        let interval: any = setInterval(() => {
            if (typeof window._bbSocket !== 'undefined') {
                clearInterval(interval);
                this.authorizeForSocket();
            }
        }, 300);
    }

    protected authorizeForSocket(): void {
        $.ajax({
            url: '/member-api/socket-auth?channel=' + this.channels.join(','),
            success: (r) => {
                console.log('success', r);
                this.initSocket(r.authUrl);
            },
            error: (r) => {
                console.log('error', r);
                if (r.status === 401) {
                    window.location.href = '/member/login';
                } else {
                    setTimeout(() => {
                        this.authorizeForSocket();
                    }, 1000);
                }
            },
        });
    }

    protected initSocket(url: string): void {
        let forcedDisconnect: boolean = false;

        let socket = new io(url, {
            reconnection: false,
            upgrade: false,
            transports: ['websocket'],
        });
        socket.on('connect', () => {
            console.log('>> socket:connect');
        });
        socket.on('disconnect', () => {
            console.log('>> socket:disconnect', forcedDisconnect);
            if (!forcedDisconnect) {
                setTimeout(() => {
                    this.authorizeForSocket();
                }, 1000);
            }
        });
        socket.on('disconnect_by_session_id', () => {
            console.log('>> socket:disconnect_by_session_id');
            forcedDisconnect = true;
            let message: string;
            if (window._demoEnabled) {
                message = this.translate.instant('DEMO_EXPIRED_MODAL_MESSAGE');
            } else {
                message = this.translate.instant('SOCKET_LOGOUT_MODAL_MESSAGE');
            }
            this.modalService.alertModal({
                message: message,
            }).then(() => {
                window.location = '/member/logout';
            });
        });
        socket.on('update', (data) => {
            console.log('>> socket:update', data, moment().format('HH:mm:ss'));
            for (let d of data.data) {
                d.content = pako.inflate(d.content, {to: 'string'});
            }
            window._bbSocket.onUpdate(data);
        });
        socket.on('channels_content', (data) => {
            console.log('>> socket:channels_content', data);
            for (let c of data.data) {
                for (let d of c.data) {
                    d.content = pako.inflate(d.content, {to: 'string'});
                }
            }
            window._bbSocket.onInitialUpdate(data);
        });
    }
}
