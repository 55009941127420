define(function(require) {
    'use strict';

    var Marionette = require('marionette');
    var Geppetto = require('geppetto');
    var tpl = require('raw-loader!wlants-dialog/dialog.jhtml');
    var _string = require('underscore.string');

    return Marionette.ItemView.extend({
        tagName: 'div',
        className: 'modal default-modal',
        template: _.template(tpl),
        animDuration: 400,
        events: {
            'click .action-btn': function(e) {
                var el = e.target;
                var action = $(el).data('action');
                action = 'action' + _string.capitalize(action);
                this.trigger(action, e);
            }
        },
        wiring: [
            'logger',
            'helpersLocator'
        ],
        initialize: function() {
            Geppetto.bindContext({
                view: this,
                context: Geppetto.Context,
                parentContext: this.options.parentContext
            });
        },
        clear: function() {
            this.remove();
        },
        onRender: function() {
            var headTitle = this.getHeadTitle();
            if (headTitle) {
                if (headTitle instanceof jQuery) {
                    this.$el.find('.modal-header').html('').append(headTitle);
                } else {
                    this.$el.find('.modal-header .modal-title').html(headTitle);
                }
            }
            var bodyClass = this.getBodyClass();
            if (bodyClass) {
                this.$el.find('.modal-body').addClass(bodyClass);
            }
            if (typeof this._btns !== 'undefined') {
                this.$el.find('.action-btn').hide();
                for (var i in this._btns) {
                    var btnClass = this._btns[i];
                    var btnText = '';
                    if (typeof btnClass !== 'string') {
                        btnText = this._btns[i].btnText;
                        btnClass = this._btns[i].btnClass;
                    }
                    if (btnClass === 'close') {
                        btnClass = 'cancel-close';
                    }
                    this.$el.find('.action-btn.' + btnClass).show();
                    if (btnText !== '') {
                        this.$el.find('.action-btn.' + btnClass).text(btnText);
                    }
                }
            }
            if (typeof this._btns === 'undefined' || !this._btns.length) {
                this.$el.find('.modal-footer').hide();
            }
            if (this.getHideHeader()) {
                this.$el.find('.modal-header').hide();
            }
            this.$el.find('.response-msg').hide();
            if (this.getBodyContent()) {
                this.$el.find('.modal-body .content').prepend(this.getBodyContent());
            } else {
                this.$el.find('.modal-body').hide();
            }
            return this;
        },
        show: function() {
            this.$el.addClass('fade');
            this.$el.attr('role', 'dialog');
            this.$el.attr('aria-hidden', 'true');
            $('body').append(this.$el);
            var self = this;
            this.$el.on('shown.bs.modal', function(e) {
                self.trigger('shown', e);
            });
            this.$el.on('hidden.bs.modal', function(e) {
                self.trigger('hidden', e);
            });
            this.$el.modal({
                keyboard: false,
                backdrop: 'static'
            });
        },
        hide: function() {
            this.$el.modal('hide');
        },
        showLoader: function() {
            this.$el.find('.modal-loader').fadeIn('fast');
        },
        hideLoader: function() {
            this.$el.find('.modal-loader').fadeOut('fast');
        },
        showFlashMessages: function(messages) {
            var types = {
                '0': 'error',
                '1': 'error',
                '2': 'info',
                '3': 'success'
            };
            try {
                for (var i in messages) {
                    console.log(messages[i]);
                    if (typeof messages[i] !== 'object') {
                        this.setResponseMessage(types[messages[i].flag], messages[i].msg);
                    } else {
                        for (var j in messages[i]) {
                            this.setResponseMessage(types[messages[i][j].flag], messages[i][j].msg);
                        }
                    }
                }
            } catch (err) {
                this.logger.debug('Dialog::showFlashMessages() error: ' + err.message);
            }
        },
        hideResponseMessages: function() {
            this.$el.find('.response-msg').fadeOut();
        },
        setResponseMessage: function(type, message) {
            this.$el.find('.response-msg.' + type + ' .message').append('<li>' + message + '</li>');
            this.$el.find('.response-msg.' + type).fadeIn();
            this.$el.find('.modal-body').fadeIn();
        },
        setButtons: function(btns) {
            this._btns = btns;
            return this;
        },
        setHideHeader: function(flag) {
            this._hideHeader = flag;
            return this;
        },
        getHideHeader: function() {
            return this._hideHeader;
        },
        setHeadTitle: function(html) {
            this._headTitle = html;
            return this;
        },
        getHeadTitle: function() {
            return this._headTitle;
        },
        setBodyContent: function(el) {
            this._bodyContEl = el;
            return this;
        },
        getBodyContent: function() {
            return this._bodyContEl;
        },
        setBodyClass: function(arg) {
            this._bodyClass = arg;
            return this;
        },
        getBodyClass: function() {
            return this._bodyClass;
        },
        getApp: function() {
            return window.iceApp;
        },
        serializeData: function() {
            return {
                helpers: this.helpersLocator.getHelpers()
            };
        }
    });
});
