define(function(require) {
    'use strict';

    var Helper = function(opts) {
        this.context = opts.context;
        this.config = this.context.getObject('app').getConfig().routes;
    };

    Helper.prototype.route = function(routeName, params) {
        if (arguments[0] === '_self_') {
            return this;
        }

        this.config = this.context.getObject('app').getConfig().routes;
        var url;

        if (typeof this.config[routeName] === 'undefined') {
            throw 'invalid route name ' + routeName;
        } else {
            if (typeof this.config[routeName].params === 'undefined') {
                url = this.config[routeName].url;
            } else {
                url = this.config[routeName].url;
                for (var i in this.config[routeName].params) {
                    var paramName = this.config[routeName].params[i];
                    var paramVal = params[paramName];
                    url = url.replace(':' + paramName, paramVal);
                }
            }
        }
        url = '#' + url;

        return url;
    };

    return Helper;
});
