define(function (require) {
    'use strict';

    var Marionette = require('marionette');

    var config = require('wlants-site/config');

    var LoginView = require('wlants-site/views/auth/login');
    var RegisterView = require('wlants-site/views/auth/register');
    var RequestResetPasswordView = require('wlants-site/views/auth/request-reset-password');
    var ResetPasswordView = require('wlants-site/views/auth/reset-password');
    var ChangePasswordView = require('wlants-site/views/auth/change-password');
    var ContactView = require('wlants-site/views/contact');
    var MarketReviewView = require('wlants-site/views/market-review');
    var ToolsIntro = require('wlants-site/services/tools-intro');
    var LabelsTest = require('wlants-site/views/labels-test');

    // let wlantsCustom = require('wlants-common/custom');
    // let SocketClient = require('wlants-site/services/socket/client');
    let SocketTables = require('wlants-site/views/socket/tables');

    return Marionette.Object.extend({
        initialize: function (opts) {
            this.context = opts.context;
            this.listenToOnce(this.context.getObject('app'), 'bootstrap', this.bootstrap);
            this.listenToOnce(this.context.getObject('app'), 'run', this.run);
        },
        bootstrap: function () {
            this.context.getObject('app').addConfig(config);
            this.initContext();
        },
        run: function () {
            this.init();
        },
        initContext: function () {
            this.initializeSingleton(ToolsIntro, 'memberToolsIntro');
        },
        initializeSingleton: function (object, name) {
            this.context.wireSingleton(name, object);
            this.context.configure(name, {
                context: this.context
            });
            this.context.getObject(name);
        },
        wireObject: function (object, valueName, signletonName) {
            if (typeof valueName !== 'undefined') {
                this.context.wireValue(valueName, object);
            }
            if (typeof signletonName !== 'undefined') {
                this.context.wireSingleton(signletonName, object);
                if (signletonName.indexOf('Collection') > -1) {
                    this.context.configure(signletonName, undefined, {
                        context: this.context
                    });
                } else {
                    this.context.configure(signletonName, {
                        context: this.context
                    });
                }
            }
        },
        // initSocket: function (channels) {
        //     let socketClient = new SocketClient({
        //         context: app.context,
        //     });
        //     let socketTables = new SocketTables();
        //
        //     let self = this;
        //
        //     socketTables.listenTo(socketClient, 'client:channels_content', socketTables.onInitialUpdate);
        //     socketTables.listenTo(socketClient, 'client:update', socketTables.onUpdate);
        //     socketClient.listenTo(socketClient, 'client:logout', function () {
        //         if (window._demoEnabled) {
        //             alert(self.context.getObject('helpersLocator').getHelpers().translate('DEMO_EXPIRED_MODAL_MESSAGE'));
        //         } else {
        //             alert(self.context.getObject('helpersLocator').getHelpers().translate('SOCKET_LOGOUT_MODAL_MESSAGE'));
        //         }
        //         window.location = '/member/logout';
        //     });
        //
        //     socketClient.setChannels(channels).init();
        // },
        init: function () {
            if ($('#user_login_form_wrap').length) {
                var loginView = new LoginView({
                    parentContext: this.context
                });
                loginView.render();
            }
            if ($('#user_register_form_wrap').length) {
                var registerView = new RegisterView({
                    parentContext: this.context
                });
                registerView.render();
            }
            if ($('#user_request_reset_password_form_wrap').length) {
                var requestResetPasswordView = new RequestResetPasswordView({
                    parentContext: this.context
                });
                requestResetPasswordView.render();
            }
            if ($('#user_reset_password_form_wrap').length) {
                var resetPasswordView = new ResetPasswordView({
                    parentContext: this.context
                });
                resetPasswordView.render();
            }
            if ($('#user_change_password_form_wrap').length) {
                var changePasswordView = new ChangePasswordView({
                    parentContext: this.context
                });
                changePasswordView.render();
            }
            if ($('#contact_form_wrap').length) {
                var contactView = new ContactView({
                    parentContext: this.context
                });
                contactView.render();
            }
            if ($('#market_review_filter').length) {
                var marketReviewView = new MarketReviewView({
                    parentContext: this.context,
                    el: $('#market_review_filter')
                });
                marketReviewView.init();
            }
            if ($('#test-labels-js').length) {
                // $('#test-labels-js').html('From JS: ' + );
                var labelsTest = new LabelsTest({
                    parentContext: this.context
                });
                labelsTest.init();
                $('#test-labels-js').append(labelsTest.$el);
            }

            // wlantsCustom();

            // if (window._subscribeChannels) {
            //     console.log(window._subscribeChannels);
            //     this.initSocket(window._subscribeChannels);
            // }

            if (window._subscribeChannels) {
                let socketTables = new SocketTables();
                window._bbSocket = {
                    onInitialUpdate: socketTables.onInitialUpdate,
                    onUpdate: socketTables.onUpdate
                };
            }
        }
    });
});
